.header {
  position: sticky;
  top: -67px;
  z-index: 2;

  .logo {
    max-width: 230px;
  }

  .search {
    margin-left: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__general {
    background-color: #1e1e1e;
    padding-top: 10px;
    padding-bottom: 10px;
    .container {
      display: grid;
      grid-template-columns: auto 260px 260px;
      // grid-template-columns: auto 260px 300px;
      align-items: center;
    }
    
    .button__plans {
      margin-left: 30px;
    }

    div:last-child {
      justify-self: flex-end;
    }

    * {
      color: white;
    }
  }

  &__nav {
    background-color: #fafafa;

    * {
      color: black;
      text-transform: uppercase;
      font-weight: 400;
      font-size: rem(15px);
    }
  }

  &__menu {
    background-color: #fafafa;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }

    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin: 0 1vw;
    padding: 0;
    line-height: 1.9;

    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }

    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #3e3e3e;

    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }

    &:active, &--active, &--open, &:hover {
      color: $color-main;
      text-decoration: underline;
    }
  }

  @include mq($until: xl) {

    &__general {
      .container {
        grid-template-columns: auto 260px 245px;
      }
    }

    &__menuItem {
      margin: 0 .7vw;
      line-height: 1.8;
      margin-top: 2px;
    }

    &__nav {
      * {
        font-size: rem(14px);
      }
    }
  }

  @include mq($until: lg) {

  }

  @include mq($until: md) {
    &__general {
      .container {
        grid-template-columns: 25% auto 220px;
      }
    }

    &__nav {
      * {
        font-size: rem(18px);
        padding-top: 0;
      }
    }

    &__menuItem {
      span {
        color: white;
        width: 30px;
        border-bottom: 1px solid black;
        display: block;
        line-height: 0;
      }
    }
  }

  @include mq($until: sm) {

    .button {
      display: none;
    }

    &__general .container{
      grid-template-columns: .5fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: row;
      row-gap: 15px;
      grid-template-areas:
        "a1 a2"
        "a3 a3";
    }
    
    .a1 { 
      grid-area: a1; 
    }
    
    .a2 { 
      grid-area: a2; 
    }
    
    .a3 { 
      grid-area: a3;
      text-align: center;
     }
  }
}

#bepro-mobile {
  display: none;

  @include mq($until: sm) {
    display: block;
    margin-top: -1px;
    background-color: black;
    padding-bottom: 20px;

    .button {
      margin: 0 auto;
      display: block;
      color: white;
    }
  }
}