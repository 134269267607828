.subsite {

    background-color: #fafafa;
    
    &__content {
        background-color: #fafafa;

        &.textpage {
            margin-top: 50px;
            margin-bottom: 100px;
            h1 {
                text-transform: uppercase;
            }
            h1, h4 {
                font-family: $font-family;
            }
        }

    }

    .products {
        background-color: #fafafa;
        padding-top: $v-mar-xxl;
        padding-bottom: $v-mar-xxl;
        margin-bottom: 0;

        h2 {
            font-weight: 300;
        }
    }

    .subscription {
        background-color: #1e1e1e;
    }

    &__hero {
        display: flex;
        flex-direction: column;
        min-height: 600px;
        justify-content: center;
        background-position: center;
        background-size: cover;
        p {
            font-family: "Questrial", sans-serif;
            font-size: rem(22px);
        }
        h1 {
            text-align: left;
            margin-top: 0;
            font-size: rem(60px);
            line-height: 1;
            text-shadow: 0 0 5px white;
        }
    }

    @include mq($until: xl) {
        .products { 
            padding-top: $v-mar-xl;
            padding-bottom: $v-mar-xl;
        }
    }

    @include mq($until: lg) {
        .products { 
            padding-top: $v-mar-lg;
            padding-bottom: $v-mar-lg;
        }
    }

    @include mq($until: md) {
        .products { 
            padding-top: $v-mar-md;
            padding-bottom: $v-mar-md;
        }
        &__hero {
            display: flex;
            flex-direction: column;
            min-height: 400px;
            height: calc(100vh - 165px);
        }
    }

    @include mq($until: sm) {
        .products { 
            padding-top: $v-mar-sm;
            padding-bottom: $v-mar-sm;
        }
    }


}


.about-us {
    &__text {
        margin-top: 100px;
        margin-bottom: 100px;

        &--sm-margin {
            margin-top: 50px;
            margin-bottom: 50px;
        }
        p {
            max-width: 520px;
            margin-right: auto;
            margin-left: auto;
            text-align: center;

            a {
                text-decoration: underline;
                font-weight: 600;
            }
        }

        h2 {
            font-family: 'Aller', sans-serif;
            font-style: italic;
            font-size: 60px;
            b {
                font-style: inherit;
                text-shadow: 0 0 1px black;
            }
        }

        h5 {
            font-family: 'Aller', sans-serif;
            margin-top: 0;
        }
    }

    @include mq($until: md) {
        &__text {
            margin-top: 70px;
            margin-bottom: 70px;
    
            &--sm-margin {
                margin-top: 35px;
                margin-bottom: 35px;
            }
        }
    }
}

.fullwidth-bg {
    min-height: 250px;
    background-position: center;
    background-size: cover;
}