.account {
    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 100px;
    padding: 0 100px 40px;
    margin-left: auto;
    margin-right: auto;

    &__register, &__login {
        max-width: 600px;
        margin: 0 auto;
        width: 100%;
    }

    input {
        background: none;
        border: none;
        border-bottom: 1px solid #1e1e1e;
        border-radius: 0;
        padding: 0.375rem 0;
        
        &:focus {
            box-shadow: none;
            background: none;
            border-color: white;
        }
    }

    label {
        font-size: rem(14px);
    }

    h3 {
        font-family: $aller-font;
    }

    button {
        background: white;
        border: 1px solid black;
        border-radius: 50px;
        transition: all .3s;
        padding: 5px 15px;

        &:hover {
            background: #fafafa;
        }
    }

    .checkbox {
        input {
            margin-right: 10px;
        }
    }

    + p{
        text-align: center;
    }

    @include mq($until: xl) {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        padding: 0 50px;
    }


    @include mq($until: lg) {
        padding: 0;
    }

    @include mq($until: md) {
        margin-bottom: 100px;
    }

    @include mq($until: sm) {
        margin-bottom: 70px;

        grid-template-columns: 1fr;
    }
}